import { useState } from 'react';
import { useSelector } from 'react-redux';
import { Link, NavLink, useLocation } from 'react-router-dom';

import { cls, scrollTop } from '../../../helper';

import Container from '../../elements/Container/Container';
import Section from '../../elements/Section/Section';

import { PhoneIcon } from '../../../icons/PhoneIcon';
import { TimeIcon } from '../../../icons/TimeIcon';
import { Icon } from '../../../icons/Icon';

import { MiniCart } from './MiniCart';

import './Header.css';



// TODO: move to backend
const title = 'PizzaTime';

const menuItems = [
  { title: 'Ementa', path: '/menu' },
  { title: 'Ofertas', path: '/promos' },
  { title: 'Informação', path: '/info' },
  { title: 'Fotogaleria', path: '/gallery' },
  { title: 'Sugestões', path: '/reviews' },
];



function Header({ ...rest }) {
  const { pathname } = useLocation();

  const isHomePage = pathname === '/';

  const [menu, setMenu] = useState(false);

  const { isManager } = useSelector(state => state.settings);

  const items = menuItems;

  const handleClick = () => {
    setMenu(false);
    scrollTop();
  }

  const classes = cls('Header', isHomePage && !menu && 'Header-transpatent', isManager && 'Header-manager');

  return (
    <header className={classes}>
      <Container className="Header-Body flex flex-gap justify-between align-center" {...rest}>
        <button
          type="button"
          className={`MenuButton button hide-medium hide-large${menu?' opened':''}`}
          onClick={e => setMenu(s => !s)}
          title="Menu principal"
        >
          <span></span>
          <span></span>
          <span></span>
        </button>

        <ul
          role="navigation"
          className={cls('Header-Menu', 'menu-inline', 'flex-grow', !menu && 'hide-narrow')}
        >
          {items.map(item => (
            <li key={item.path}>
              <NavLink
                to={item.path}
                className={({ isActive }) => (isActive?'active':undefined)}
                onClick={handleClick}
              >
                {item.title}
              </NavLink>
            </li>
          ))}
        </ul>

        <Link
          to="/"
          onClick={scrollTop}
          className="Header-Logo flex-unshrink"
          title="Ir para a página principal" role="button"
        >
          <img src="/images/logo.svg" alt={title} importance="high" />
        </Link>

        <div className={`Header-Info flex flex-gap-half flex-unshrink hide-medium hide-narrow`}>
          <div className="flex flex-gap-half align-center">
            <Icon primary>
              <PhoneIcon />
            </Icon>
            <Section name="Header - Column 4" />
          </div>
          <div className="flex flex-gap-half align-center">
            <Icon primary>
              <TimeIcon />
            </Icon>
            <Section name="Header - Column 5" />
          </div>
        </div>

        <MiniCart />
        
      </Container>
    </header>
  );
}

export default Header;
