import { useDispatch, useSelector } from 'react-redux';

import {
  setCurrent as setCurrentProductForVariants,
  setVariants
} from '../../../state/variantsSlice';

import './Products.css';



export const ProductVariants = () => {
  const { variants, current } = useSelector(state => state.variants);
  
  const productId = current ? current.ID || 0 : 0;

  const selected = variants[productId] || [];

  const available = current && current.Meta && current.Meta['Variants']
    ? JSON.parse(current.Meta['Variants']) || {} : {};

  const dispatch = useDispatch();

  const setVari = (selected) => {
    dispatch(setVariants({ productId, selected }));
  };

  const close = () => dispatch(setCurrentProductForVariants(null));

  return productId !== 0 && (
    <div className="ProductVariantsSelect-wrap" onClick={close}>
      <div className="ProductVariantsSelect" onClick={e => e.stopPropagation()}>
        <div className="ProductOptionsSelectTitle">
          <span>Escolha variantes</span>
          <button
            type="button"
            className="ProductOptionsSelectClose"
            onClick={close}
          >
            ×
          </button>
        </div>
        <div className="ProductVariantsList">
          {Object.keys(available).map(prop =>  (
            <div className="ProductVariantsListItem" key={prop}>
              <span className="ProductVariantsListItemTitle">{prop}: </span>
              {Object.keys(available[prop]).map(item => (
                <button
                  key={item}
                  type="button"
                  className={`ProductVariantsListSubitem${selected[prop]!==item ? '' : ' current'}`}
                  onClick={() => setVari({...selected, [prop]: item })}
                >
                  {item}
                </button>
              ))}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ProductVariants;
