import { useDispatch, useSelector } from 'react-redux';

import { currency } from '../../../constants';

import {
  setCurrentProduct as setCurrentProductForOptions,
  setSelectedOptions
} from '../../../state/optionsSlice';

import Fetching from '../../elements/Fetching/Fetching';

import './Products.css';



const getOptionPrice = (option, variants) => {
  let optionPrice = Number(option.Meta['Price']) || 0;
  const optionPrices = option.Meta['Variants'] ? JSON.parse(option.Meta['Variants']) || {} : {};
  Object.keys(optionPrices).forEach(v => {
    optionPrice = Number(optionPrices[v][variants[v]]) || 0;
  });
  return optionPrice;
};

const ProductOptions = () => {
  const {
    fetching,
    all: allOptions,
    selected: options,
    currentProduct: current,
    currentProductVariants: variants
  } = useSelector(state => state.options);

  const productId = current ? current.ID || 0 : 0;

  const avbailable = current && current.Meta && current.Meta['Options']
    ? JSON.parse(current.Meta['Options']) || [] : [];

  const selected = options[productId] || [];

  const dispatch = useDispatch();

  const addOption = (option, add = true) => {
    const same = selected.find(s => s.option.ID === option.ID);
    let newValue = null;
    if (!same) {
      const newOption = { option, quantity: 1 };
      newValue = [...selected, newOption];
    } else {
      newValue = [...selected];
      const newQuantity = same.quantity + (add ? 1 : -1);
      const del = newValue.indexOf(same);
      if (del >= 0) {
        if (newQuantity > 0)
          newValue.splice(del, 1, { option, quantity: newQuantity });
        else newValue.splice(del, 1);
      }
    }
    dispatch(setSelectedOptions({ productId, selected: newValue }));
  }

  const close = () => dispatch(setCurrentProductForOptions({}));
  
  return productId !== 0 && avbailable.length > 0 && (
    <div className="ProductOptionsSelect-wrap" onClick={close}>
      <div className="ProductOptionsSelect" onClick={e => e.stopPropagation()}>
        <div className="ProductOptionsSelectTitle">
          <span>Adicionar opções</span>
          <button
            type="button"
            className="ProductOptionsSelectClose"
            onClick={close}
          >
            ×
          </button>
        </div>
        <div className="ProductOptionsSelected">
          <div className="ProductOptionsSelectedTitle">
            Opções adicionadas
            ({Math.round(selected.reduce((acc, s) => acc + getOptionPrice(s.option, variants) * s.quantity, 0) * 100) / 100} {currency})
          </div>
          <div className="ProductOptionsSelectedList">
            {selected.length > 0 ? selected.map(s => (
              <div key={s.option.ID} className="ProductOptionsSelectedItem">
                <button type="button" className="ProductOptionsDecrease" onClick={() => addOption(s.option, false)}>
                  {s.quantity > 1 ? '-' : '×'}
                </button>
                <div className="ProductOptionsItemTitle">{s.option.Title} × {s.quantity}</div>
                <button type="button" className="ProductOptionsIncrease" onClick={() => addOption(s.option)}>
                  +
                </button>
              </div>
            )) : (
              <div className="ProductOptionsSelectedEmpty">nenhum</div>
            )}
          </div>
        </div>
        <div className="ProductOptionsList">
          {fetching && allOptions.length === 0 ? <Fetching /> : (
            allOptions.filter(o => avbailable.find(a => a === o.ID) !== undefined)
              .sort((a, b) => a.Content > b.Content ? 1 : (a.Content === b.Content ? 0 : -1))
              .map(option => (
                <button
                  type="button"
                  key={option.ID}
                  title={option.Content}
                  className={`ProductOptionsListItem${selected.find(s => s.option.ID === option.ID) ? ' selected' : ''}`}
                  onClick={() => addOption(option)}
                >
                  <span>{option.Title}</span>
                  <span>{getOptionPrice(option, variants)} {currency}</span>
                </button>
              )
            ))}
        </div>
      </div>
    </div>
  );
};

export default ProductOptions;
