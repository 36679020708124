import Container from '../../elements/Container/Container';
import Section from '../../elements/Section/Section';

import './Info.css';



// TODO: move to settings
const topItems = [
  { title: 'Contatos', section: 'Info - Top Column 1' },
  { title: 'Horario', section: 'Footer - Column 2' },
  { title: 'Como chegar', section: 'Info - Top Column 3' },
  { title: 'Mídia social', section: 'Footer - Column 3' },
];

const mapSection = 'Info - Map';

const bottomHeading = 'Envio e pagamento';

const bottomItems = [
  { title: 'Aceitam-se encomendas', section: 'Info - Bottom Column 1', image: '/images/time.svg' },
  { title: 'Condições de entrega', section: 'Info - Bottom Column 2', image: '/images/delivery.svg' },
  { title: 'Métodos de pagamento', section: 'Info - Bottom Column 3', image: '/images/money.svg' },
];

const noticeSection = 'Info - Notice';



function InfoItem({ item }) {
  const { title, section, image } = item;
  return (
    <article className="InfoItem">
      <div className="flex flex-gap-half">
        {image && <div><img src={image} alt="" /></div>}
        <div className="flex-grow">
          <h2 className="InfoItemTitle">{title}</h2>
          <Section className="InfoItemContent" name={section} />
        </div>
      </div>
    </article>
  );
}

function InfoList({ items, sparse, ...rest }) {
  return items.length > 0 ? (
    <div className={`InfoList fade-in ${sparse ? 'sparse' : ''}`} {...rest}>
      {items.map((item) => (
        <InfoItem key={item.title} item={item} />
      ))}
    </div>
  ) : null;
}

function InfoTop() {
  return (
    <InfoList sparse items={topItems} style={{ '--animation-order': 1 }} />
  );
}

function InfoMap() {
  return (
    <div className="InfoMap space-one fade-in" style={{ '--animation-order': 2 }}>
      <Section name={mapSection} className="InfoMapContent" />
    </div>
  );
}

function InfoNotice() {
  return (
    <div className="flex flex-gap-half space-one fade-in" style={{ '--animation-order': 5 }}>
      <div><img src="/images/info.svg" alt="" /></div>
      <Section name={noticeSection} className="InfoNotice" />
    </div>
  );
}

function InfoBottom() {
  return (<>
    <h2 className="InfoHeading fade-in" style={{ '--animation-order': 3 }}>{bottomHeading}</h2>
    <InfoList items={bottomItems} style={{ '--animation-order': 4 }} />
    <InfoNotice />
  </>);
}

function Info() {
  return (
    <div className="Info">
      <Container>
        <div className="flex flex-column flex-gap">
          <InfoTop />
          <InfoMap />
          <InfoBottom />
        </div>
      </Container>
    </div>
  );
}

export default Info;
