import { useEffect, useId, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';

import { cls } from '../../../helper';

import { currency, IS_DEV } from '../../../constants';
import { makeOrder } from '../../../requests';

import { add as addMessage } from '../../../state/notifySlice';
import { addPastOrder } from '../../../state/orderSlice';
import { clear, save } from '../../../state/cartSlice';
import {
  setPromoAvailable,
  setSelectedProducts,
  setPromoProducts,
  clearSelectedProducts,
} from '../../../state/promosSlice';

import Container from '../../elements/Container/Container';
import Fetching from '../../elements/Fetching/Fetching';
import Modal from '../../elements/Modal/Modal';
import Image from '../../elements/Image/Image';

import { CartItem } from '../../components/Cart/Cart';

import { PRODUCT_PLACEHOLDER } from '../Products/Products';

import './Order.css';



const defaults = {
  delivery: true,
  payment: 'Cash'
}

const MIN_PHONE_LENGTH = 13;

const phoneRegex = /^\+351.*$/;

const checkPromos = (promos = [], cart = []) => {
  const available = [];

  promos.forEach(promo => {
    let items = [...cart];

    if (IS_DEV) console.log(promo['Title'], promo['Conditions']);

    const { Weekdays: wd, Quantity: q, Price: p, ProductID: pid, ProductVariant: pv } = promo['Conditions'];

    const pass = {
      entries: 0,
      price: true,
      products: true,
      quantity: true,
      variants: true,
      weekdays: true
    };

    // (ok) check weekdays
    if (wd && wd.length > 0) {
      const today = new Date().getDay();
      pass.weekdays = wd.includes(today);
      if (IS_DEV) console.log(`- weekdays${pass.weekdays?'':' not'} passed`);
    }
    
    // (ok) filter products
    if (pid && pid.length > 0) {
      items = items.filter(item => {
        return pid.includes(item.product.ID);
      });
      if (IS_DEV) console.log('- filtered by id', items);
      pass.products = items.length > 0;
    }

    // (ok) filter product variants
    if (pv && pv.length > 0) {
      items = items.filter(item => {
        let same = true;
        pv.forEach(v => Object.keys(v).forEach(k => {
          if (item.variants[k] !== v[k]) same = false;
        }));
        return same;
      });
      if (IS_DEV) console.log('- filtered by variants', items);
      pass.variants = items.length > 0;
    }

    // (ok) check quantity
    if (q && q > 0) {
      let count = 0;
      items.forEach(item => {
        count += item.quantity;
      });
      pass.quantity = count >= q;
      if (IS_DEV) console.log(`- quantity${pass.quantity?'':' not'} passed`);
      pass.entries = Math.floor(count / q);
      if (IS_DEV) console.log('- entries', pass.entries);
    }

    // (~) check price
    if (p && p > 0) {
      let total = 0;
      items.forEach(item => {
        total += item.price * Math.min(q, item.quantity);
      });
      pass.price = total >= p;
      if (IS_DEV) console.log(`- price${pass.price?'':' not'} passed`);
    }

    // (~) count entries
    const cond = pass.price && pass.products && pass.quantity && pass.variants && pass.weekdays;
    pass.entries = cond ? pass.entries : 0;

    if (IS_DEV) console.log('- pass results', pass);

    available.push({ id: promo['ID'], count: pass.entries });

    if (IS_DEV) console.log('-----');
  });

  return available;
};

export const OrderProduct = ({ item }) => {
  return (
    <div key={item.id}className="OrderProduct">
      <div className="flex justify-between">
        <div className="OrderProduct-title flex-ungrow">{item.product.Title}</div>
        <div className="flex-unshrink">
          <span className="OrderProduct-price">
            {Math.round(item.price * 100) / 100} {currency}
          </span> &times; {item.quantity}
        </div>
      </div>
      <div className="OrderProduct-variants">
        {Object.keys(item.variants).map(prop => (
          <span key={prop}>{prop}: {item.variants[prop]}</span>
        ))}
      </div>
      <div className="OrderProduct-options">
        {item.options.map(o => (
          <span key={o.id || o.option.ID}>
            {o.title || o.option.Title} &times; {o.quantity}
          </span>
        ))}
      </div>
    </div>
  );
};

const asReady = 'Quando pronto';

function OrderForm() {
  const restId = useId();

  const { isWorking } = useSelector(state => state.workingTime);
  const { items: products } = useSelector(state => state.products);
  const { items, quantity, total } = useSelector(state => state.cart);
  const {
    promos,
    available,
    selectedProducts: sp,
    selectIndex,
    selectPromoId,
    selectProducts,
    selectVariant
  } = useSelector(state => state.promos);

  const { settings, isManager } = useSelector(state => state.settings);

  let dc = { enabled: false, cost: 0, minCartTotal: 0 };

  const deliveryConfigRaw = settings['Delivery Config'] || '';
  try { dc = deliveryConfigRaw ? JSON.parse(deliveryConfigRaw) || dc : dc; }
  catch (e) { console.error(e); }

  if (isManager) defaults.delivery = false;

  // if (IS_DEV) console.log(sp, Object.keys(sp).map(id => Object.keys(sp[id]).map(i => sp[id][i]).flat()).flat());
  
  const order = localStorage.getItem('order');
  const saved = !isManager && order ? JSON.parse(order) : {};

  const [fetching, setFetching] = useState(false);

  const [restMode, setRestMode] = useState(isManager);

  const [name, setName] = useState(saved.name || '');
  const [phone, setPhone] = useState(saved.phone || '+351');
  const [email, setEmail] = useState(saved.email || '');
  const [tax, setTax] = useState(saved.tax || '');

  const [delivery, setDelivery] = useState(dc.enabled && defaults.delivery);
  const [street, setStreet] = useState(saved.street || '');
  const [building, setBuilding] = useState(saved.building || '');
  const [apartment, setApartment] = useState(saved.apartment || '');
  const [entrance, setEntrance] = useState(saved.entrance || '');
  const [floor, setFloor] = useState(saved.floor || '');
  const [payment, setPayment] = useState(saved.payment || defaults.payment);

  const [comment, setComment] = useState('');
  const [time, setTime] = useState(asReady);

  const [errors, setErrors] = useState([]);

  const navigate = useNavigate();

  const dispatch = useDispatch();
  
  const filteredPromos = promos ? promos.filter(promo => available[promo['ID']] > 0) : [];

  const superTotal = total + (delivery ? dc.cost : 0);
  
  const placeOrder = () => {
    setFetching(true);
    const data = {
      contact: { name, phone, email },
      comment,
      time,
      delivery,
      deliveryCost: dc.cost,
      payment,
      tax,
      address: { street, building, apartment, entrance, floor },
      products: items.map(item => ({
        product: {
          ID: item.product.ID,
          Title: item.product.Title
        },
        variants: item.variants,
        options: item.options.map(o => ({
          id: o.option.ID,
          title: o.option.Title,
          quantity: o.quantity
        })),
        quantity: item.quantity,
        price: item.price
      })),
      promos: Object.keys(sp).map(id => Object.keys(sp[id]).map(i => sp[id][i]).flat()).flat().map(item => ({
        product: {
          ID: item.product.ID,
          Title: item.product.Title
        },
        variants: item.variants
      })),
      restaurant: restMode,
      quantity,
      total
    };
    if (IS_DEV) console.log('Order', data);
    makeOrder(data)
      .then(({ data }) => {
        const { id, key } = data;
        if (!!id && !!key) {
          dispatch(clear());
          dispatch(save());
          if (!restMode) {
            const order = { id, key, date: new Date().toISOString() };
            dispatch(addPastOrder(order));
          }
          dispatch(clearSelectedProducts());
          dispatch(addMessage({ type: 'info', text: 'Pedido criado com sucesso' }));
          navigate(`/order/${id}/${key}`);
        } else {
          dispatch(addMessage({ type: 'warning', text: 'Erro ao criar pedido' }));
        }
      })
      .catch(error => {
        console.error(error);
        dispatch(addMessage({ type: 'error', text: 'Erro ao criar pedido' }));
      })
      .finally(() => { setFetching(false); });
  };

  const handleRestChange = () => {
    const rest = !restMode;
    setDelivery(!rest);
    setRestMode(rest);
  };

  const validate = () => {
    const errors = [];
    if (!isManager && !isWorking) errors.push('Não estou recebendo pedidos agora');
    if (!name) errors.push('Por favor, insira seu nome');
    if (!restMode && (!phone || phone.trim().length < MIN_PHONE_LENGTH))
      errors.push('O número de telefone é obrigatório');
    if (!restMode && (phone && !phone.trim().match(phoneRegex)))
      errors.push('O número de telefone deve começar com +351');
    if (delivery && total < dc.minCartTotal)
      errors.push('Valor do pedido abaixo do mínimo');
    if (delivery && (!street || !building || !apartment))
      errors.push('Por favor, preencha os campos de endereço');
    setErrors(errors);
    return errors.length === 0;
  };

  const saveFields = (replace = {}) => {
    if (isManager) return;
    const data = { name, phone, email, street, building, apartment, entrance, floor, tax, ...replace };
    localStorage.setItem('order', JSON.stringify(data));
  };

  useEffect(() => {
    const update = checkPromos(promos, items);
    update.forEach(u => {
      dispatch(setPromoAvailable(u));
    });
  }, [promos, items]);

  useEffect(() => {
    validate();
  }, [isWorking, name, phone, delivery, total, settings, street, building, apartment]);

  const promoProducts = (conditions) => {
    const pids = conditions['PromoProductID'] || [];
    const variant = conditions['PromoProductVariant'] || [];
    const promoVariant = {};

    if (IS_DEV) console.log({ pids, variant });

    const filtered = products.filter(p => {

      const pid = p['ID'];
      const idIsOk = pids.find(i => i === pid) !== undefined;

      let varIsOk = false;
      const vars = p['Meta']['Variants'] ? JSON.parse(p['Meta']['Variants']) || {} : {};

      variant.forEach(v => Object.keys(v).forEach(k => {
        if (vars[k] && Object.keys(vars[k]).includes(v[k])) {
          promoVariant[pid] = { [k]: v[k] };
          varIsOk = true;
        }
      }));

      if (IS_DEV) console.log({ product: p, promoVariant, idIsOk, varIsOk, vars });

      return idIsOk && varIsOk;
    });

    if (IS_DEV) console.log({ promoVariant });

    return [filtered, promoVariant];
  };

  const onOrderClick = () => {
    if (validate()) placeOrder();
    else dispatch(addMessage({
      type: 'warning',
      text: 'Condições do pedido não atendidas'
    }));
  };

  return (
    <div className="OrderForm">
      <div className="flex flex-column flex-gap">
      <div className="flex flex-gap align-center">
        <div className="Order-title">
          Pedido
        </div>
        {isManager && (
          <div>
            <input
              id={restId}
              type="checkbox"
              className="input-checkbox"
              defaultChecked={restMode}
              onClick={handleRestChange}
            />
            <label htmlFor={restId} className="radio-label">
              No restaurante
            </label>
          </div>
        )}
      </div>
      <div className="Order-columns">
        <div className="flex flex-column flex-gap flex-shrink">
          <div className="flex flex-column flex-gap-half">
            <div className="Order-Section-title">
              Seus contatos
            </div>
            <div className="flex flex-wrap flex-equal flex-gap">
              <div>
                <label className="input-label input-required">
                  Seu nome
                </label>
                <input
                  type="text"
                  className="input-field"
                  value={name}
                  onChange={(e) => { setName(e.target.value); saveFields({ name: e.target.value }); }}
                  placeholder="Como nos devemos dirigir a si?"
                  required
                />
              </div>
              {!restMode && (
                <div>
                  <label className="input-label input-required">
                    Número de telefone
                  </label>
                  <input
                    type="text"
                    className="input-field"
                    value={phone}
                    onChange={(e) => { setPhone(e.target.value); saveFields({ phone: e.target.value }); }}
                    placeholder="+351 987654321"
                    pattern={phoneRegex}
                    required
                  />
                </div>
              )}
            </div>
            <div className="flex flex-wrap flex-equal flex-gap">
              {!restMode && (
                <div>
                  <label className="input-label">
                    E-mail
                  </label>
                  <input
                    type="email"
                    className="input-field"
                    value={email}
                    onChange={(e) => { setEmail(e.target.value); saveFields({ email: e.target.value }); }}
                    placeholder="name@example.com"
                  />
                </div>
              )}
              <div>
                <label className="input-label">
                  Número contribuinte
                </label>
                <input
                  type="text"
                  className="input-field"
                  value={tax}
                  onChange={(e) => { setTax(e.target.value); saveFields({ tax: e.target.value }); }}
                  placeholder="000 000 000"
                />
              </div>
            </div>
          </div>
          {!restMode && (<>
            <div className="flex flex-column flex-gap">
              <div className="Order-Section-title">
                Entrega
              </div>
              <div className="ButtonGroup">
                <button className={cls('Button', delivery && 'active')} onClick={() => setDelivery(true)} >
                  Entrega
                </button>
                <button className={cls('Button', !delivery && 'active')} onClick={() => setDelivery(false)}>
                  Levantar
                </button>
              </div>
              {!dc.enabled && <div>Só apenas para Teikaway</div>}
              {delivery && dc.minCartTotal > 0 && (
                <div>Carrinho mínimo: {dc.minCartTotal} {currency}</div>
              )}
            </div>
            {delivery && (
              <div>
                <div className="flex flex-column flex-gap-half">
                  <div className="Order-Section-title">
                    Endereço de entrega
                  </div>
                  <div className="flex flex-wrap flex-equal flex-gap">
                    <div>
                      <label className="input-label input-required">
                        Rua
                      </label>
                      <input
                        type="text"
                        className="input-field"
                        value={street}
                        onChange={(e) => { setStreet(e.target.value); saveFields({ street: e.target.value }); }}
                        required
                      />
                    </div>
                    <div>
                      <label className="input-label input-required">
                        Número do porta
                      </label>
                      <input
                        type="text"
                        className="input-field"
                        value={building}
                        onChange={(e) => { setBuilding(e.target.value); saveFields({ building: e.target.value }); }}
                        required
                      />
                    </div>
                  </div>
                  <div className="flex flex-wrap flex-equal flex-gap">
                    <div>
                      <label className="input-label">
                        Entrada
                      </label>
                      <input
                        type="text"
                        className="input-field"
                        value={entrance}
                        onChange={(e) => { setEntrance(e.target.value); saveFields({ entrance: e.target.value }); }}
                      />
                    </div>
                    <div>
                      <label className="input-label">
                        Andar
                      </label>
                      <input
                        type="text"
                        className="input-field"
                        value={floor}
                        onChange={(e) => { setFloor(e.target.value); saveFields({ floor: e.target.value }); }}
                      />
                    </div>
                    <div>
                      <label className="input-label input-required">
                        Apartamento
                      </label>
                      <input
                        type="text"
                        className="input-field"
                        value={apartment}
                        onChange={(e) => { setApartment(e.target.value); saveFields({ apartment: e.target.value }); }}
                        required
                      />
                    </div>
                  </div>
                </div>
              </div>
            )}
          </>)}
          <div className="flex flex-gap flex-wrap flex-equal">
          {!restMode && (
            <div className="flex flex-column flex-gap-half">
              <div className="Order-Section-title">
                Hora {delivery ? 'receber' : 'levantar'}
              </div>
              <div className="flex flex-gap-half flex-column">
                <div>
                  <input className="input-radio" id="orderTimeStraight" type="radio" defaultChecked={time===asReady} onClick={() => setTime(asReady)} />
                  <label className="radio-label" htmlFor="orderTimeStraight">Quando pronto</label>
                </div>
                <div>
                  <input className="input-radio" id="orderTimeCustom" type="radio" defaultChecked={time!==asReady} onClick={() => setTime('')} />
                  <label className="radio-label" htmlFor="orderTimeCustom">Escolha o tempo</label>
                </div>
              </div>
              {time!==asReady && (
                <div className="flex flex-column flex-gap-half">
                  <input
                    className="input-field"
                    type="text"
                    value={time}
                    onChange={(e) => { setTime(e.target.value); }}
                    placeholder="Tempo"
                    required
                  />
                  <p><small>O tempo de cozimento pode variar</small></p>
                </div>
              )}
            </div>
          )}
          {delivery && (
            <div className="flex flex-column flex-gap-half">
              <div className="Order-Section-title">
                Pagamento
              </div>
              <div className="flex flex-column flex-gap-half flex-wrap">
                <div>
                  <input
                    className="input-radio"
                    id="orderPaymentCash"
                    type="radio"
                    defaultChecked={payment==='Cash'}
                    onClick={() => setPayment('Cash')}
                  />
                  <label className="radio-label" htmlFor="orderPaymentCash">Dinheiro</label>
                </div>
                <div>
                  <input
                    className="input-radio"
                    id="orderPaymentCard"
                    type="radio"
                    defaultChecked={payment==='Card'}
                    onClick={() => setPayment('Card')}
                  />
                  <label className="radio-label" htmlFor="orderPaymentCard">Cartão</label>
                </div>
              </div>
            </div>
          )}
          </div>
          <div className="flex flex-column flex-gap-half">
            <div className="Order-Section-title">
              Comente
            </div>
            <textarea
              className="input-field"
              onChange={(e) => setComment(e.target.value)}
              placeholder="correções de encomendas e dicas de entrega"
              value={comment}
            />
          </div>
          {errors.length > 0 && (
            <div className="OrderErrors">
              {errors.map(error => <div className="OrderError" key={error}>{error}</div>)}
            </div>
          )}

        </div>
        <div className="Order-split">|</div>
        <div className="Order-products flex flex-column flex-gap flex-shrink">
          <div className="flex flex-column flex-gap-half">
            <div className="Order-Section-title">
              Posições de pedidos
            </div>
            <div className="flex flex-column flex-gap-half">
              {items.map((item, index) => (
                <CartItem item={item} index={index} />
              ))}
            </div>
          </div>
          <div>
            <div className="Order-Section-title">
              Ofertas disponíveis
            </div>
            <div>
              {filteredPromos.length > 0 ? filteredPromos.map(promo => (
                <div key={promo['ID']}>
                  <div>{promo['Title']}</div>
                  <div className="PromoProductSelect-list">
                    {[...Array(available[promo['ID']])].map((_v, index) => (
                      <button
                        key={index}
                        type="button"
                        className="PromoProductSelect-button"
                        onClick={() => {
                          const [filtered, variant] = promoProducts(promo['Conditions']);
                          dispatch(setPromoProducts({ index, products: filtered, promoId: promo['ID'], variant }));
                        }}
                      >
                        {sp[promo['ID']] && sp[promo['ID']][index] ? sp[promo['ID']][index].product['Title'] : '+'}
                      </button>
                    ))}
                  </div>
                </div>
              )) : 'Nenhuma oferta está disponível para o seu pedido'}
            </div>
          </div>
        </div>
      </div>
      <div className="Order-order">
        <div className="flex justify-between align-center flex-wrap flex-gap">
          <div>
            <div className="Cart-products-count">
              <span>Produtos:</span> {quantity}
            </div>
            {delivery && (
              <div className="Cart-products-count">
                <span>Entrega:</span> {dc.cost} {currency}
              </div>
            )}
            <div className="Cart-total">
              Preço total: <span>
                {Math.round(superTotal * 100) / 100} {currency}
              </span>
            </div>
          </div>
          {fetching ? <Fetching /> : (
            <div>
              <button disabled={!isManager && !isWorking} type="button" className="button Cart-OrderButton" onClick={onOrderClick}>
                Pedido
              </button>
            </div>
          )}
        </div>
      </div>
      </div>
      {selectProducts.length > 0 && (
        <Modal title="Selecione o produto promocional" onClose={() => dispatch(setPromoProducts({}))}>
          <div className="PromoProduct-list">
            {selectProducts.map((item) => (
              <button
                key={item['ID']}
                type="button"
                className="PromoProduct"
                onClick={() => {
                  dispatch(setSelectedProducts({
                    promoId: selectPromoId, index: selectIndex, product: item, variants: selectVariant[item['ID']]
                  }));
                  dispatch(setPromoProducts({}));
                }}
              >
                <div className="PromoProduct-image">
                  <Image src={item.Image} placeholder={PRODUCT_PLACEHOLDER} />
                </div>
                <div className="PromoProduct-title">
                  {item['Title']}
                </div>
                <div className="PromoProduct-variant">
                  {Object.keys(selectVariant[item['ID']]).map(k => `${k}: ${selectVariant[item['ID']][k]}`)}
                </div>
              </button>
            ))}
          </div>
        </Modal>
      )}
    </div>
  );
}

function OrderPage() {  
  return (
    <div className="Order space-two">
      <Container>
        <OrderForm />
      </Container>
    </div>
  );
}

export default OrderPage;
