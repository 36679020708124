import { useEffect, useRef } from 'react';

import { scrollTop } from '../../../helper';

import './ScrollTop.css';



const ScrollTop = () => {
  const scrollTopRef = useRef();

  useEffect(() => {
    const onScrollEnd = () => {
      scrollTopRef.current.style.opacity = window.scrollY > 100 ? '1' : '0';
    };
    window.addEventListener('scrollend', onScrollEnd);
    return () => window.removeEventListener('scroll', onScrollEnd);
  }, []);

  return (
    <button
      type="button"
      className="ScrollTop button"
      ref={scrollTopRef}
      onClick={scrollTop}
      title="Para cima"
    >
      ^
    </button>
  );
}

export default ScrollTop;
